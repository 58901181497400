<template>
  <div>
    <v-card-title
      >{{ $t("forms.maintenanceMail.upcomingEvents") }}
    </v-card-title>

    <v-container>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            type="number"
            v-model="days"
            min="10"
            max="100"
            :prefix="$t('forms.maintenanceMail.showNext')"
            :suffix="$t('forms.maintenanceMail.days')"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table :headers="headers" :items="events" :loading="loading">
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{ name: 'Live', params: { id: item.id } }"
          exact
          target="_blank"
        >
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.start="{ item }">
        {{ $d(new Date(item.start * 1000), "shortDate") }}
      </template>
      <template v-slot:item.end="{ item }">
        <span v-if="item.start !== item.end">
          {{ $d(new Date(item.end * 1000), "shortDate") }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Repository from "../store/api/repository";

export default {
  name: "UpcomingEventsList",
  data() {
    return {
      days: 10,
      events: [],
      loading: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("events.table.name"),
          value: "name",
          sortable: false
        },
        {
          text: this.$i18n.t("events.table.startDate"),
          value: "start",
          sortable: false
        },
        {
          text: this.$i18n.t("events.table.endDate"),
          value: "end",
          sortable: false
        }
      ];
    }
  },
  watch: {
    days() {
      this.fetchUpcomingEvents();
    }
  },
  methods: {
    fetchUpcomingEvents() {
      this.loading = true;

      Repository.get(`/debug/events/upcoming?days=${this.days}`)
        .then(response => {
          this.events = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.fetchUpcomingEvents();
  }
};
</script>
