<template>
  <div>
    <v-card :loading="loading">
      <v-form @submit.prevent="submitForm">
        <v-card-title>{{
          $i18n.t("forms.maintenanceMail.title")
        }}</v-card-title>
        <v-card-subtitle>{{
          $i18n.t("forms.maintenanceMail.description")
        }}</v-card-subtitle>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="form.from.showDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('forms.maintenanceMail.from.date')"
                    :value="$d(form.from.timestamp, 'shortDate')"
                    :error-messages="fromErrors"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    @click:prepend="form.from.showDatePicker = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="form.from.timestamp | moment('YYYY-MM-DD')"
                  no-title
                  show-current
                  first-day-of-week="1"
                  @input="fromDatePickerHandler"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                ref="fromTimePicker"
                v-model="form.from.showTimePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('forms.maintenanceMail.from.time')"
                    :value="$d(form.from.timestamp, 'time')"
                    :error-messages="fromErrors"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    @click:prepend="form.from.showTimePicker = true"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="form.from.showTimePicker"
                  :value="form.from.timestamp | moment('HH:mm')"
                  format="24hr"
                  full-width
                  @input="fromTimePickerHandler"
                  @click:minute="form.from.showTimePicker = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                v-model="form.to.showDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('forms.maintenanceMail.to.date')"
                    :value="$d(form.to.timestamp, 'shortDate')"
                    :error-messages="toErrors"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    @click:prepend="form.to.showDatePicker = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="form.to.timestamp | moment('YYYY-MM-DD')"
                  no-title
                  show-current
                  first-day-of-week="1"
                  @input="toDatePickerHandler"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                ref="toTimePicker"
                v-model="form.to.showTimePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('forms.maintenanceMail.to.time')"
                    :value="$d(form.to.timestamp, 'time')"
                    :error-messages="toErrors"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    @click:prepend="form.to.showTimePicker = true"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="form.to.showTimePicker"
                  :value="form.to.timestamp | moment('HH:mm')"
                  format="24hr"
                  full-width
                  @input="toTimePickerHandler"
                  @click:minute="form.to.showTimePicker = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-alert v-if="error" dense outlined type="error">
                {{ $t(error) }}
              </v-alert>

              <v-alert v-if="success" dense outlined type="success">
                {{ $t(success) }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn type="submit" color="success" :loading="loading">
            {{ $t("forms.maintenanceMail.submit") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-card class="mt-6">
      <upcoming-events-list />
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";
import minDate from "@/utils/validators/minDate";
import upcomingEventsList from "@/components/UpcomingEventsList.vue";

export default {
  name: "PanelMaintenanceMail",
  components: {
    upcomingEventsList
  },
  data() {
    return {
      form: {
        from: {
          showDatePicker: false,
          showTimePicker: false,
          timestamp: new Date(
            new Date().setHours(new Date().getHours() + 3)
          ).getTime()
        },
        to: {
          showDatePicker: false,
          showTimePicker: false,
          timestamp: new Date(
            new Date().setHours(new Date().getHours() + 6)
          ).getTime()
        }
      },
      loading: false,
      success: null,
      error: null
    };
  },
  validations() {
    return {
      form: {
        from: {
          timestamp: {
            minValue: minDate(Date.now())
          }
        },
        to: {
          timestamp: {
            minValue: minDate(this.form.from.timestamp)
          }
        }
      }
    };
  },
  computed: {
    fromErrors() {
      const errors = [];
      if (!this.$v.form.from.timestamp.$dirty) return errors;
      !this.$v.form.from.timestamp.minValue &&
        errors.push(this.$i18n.t("forms.maintenanceMail.errors.from.minValue"));
      return errors;
    },
    toErrors() {
      const errors = [];
      if (!this.$v.form.to.timestamp.$dirty) return errors;
      !this.$v.form.to.timestamp.minValue &&
        errors.push(this.$i18n.t("forms.maintenanceMail.errors.to.minValue"));
      return errors;
    }
  },
  methods: {
    ...mapActions({
      sendMaintenanceInfo: "user/sendMaintenanceInfo"
    }),
    fromDatePickerHandler(value) {
      this.$v.form.$touch();

      this.form.from.showDatePicker = false;
      this.form.from.timestamp = this.parseDateToTimestamp(
        value,
        this.form.from.timestamp
      );
    },
    fromTimePickerHandler(value) {
      this.$v.form.$touch();

      this.form.from.timestamp = this.parseTimeToTimestamp(
        value,
        this.form.from.timestamp
      );
    },
    toDatePickerHandler(value) {
      this.$v.form.$touch();

      this.form.to.showDatePicker = false;
      this.form.to.timestamp = this.parseDateToTimestamp(
        value,
        this.form.to.timestamp
      );
    },
    toTimePickerHandler(value) {
      this.$v.form.$touch();

      this.form.to.timestamp = this.parseTimeToTimestamp(
        value,
        this.form.to.timestamp
      );
    },
    parseDateToTimestamp(date, curTimestamp) {
      const curTime = new Date(curTimestamp);
      const hours = curTime.getHours();
      const minutes = curTime.getMinutes();

      const newDate = date.split("-");
      const year = Number(newDate[0]);
      const monthIndex = Number(newDate[1]) - 1;
      const day = Number(newDate[2]);

      return new Date(year, monthIndex, day, hours, minutes).getTime();
    },
    parseTimeToTimestamp(time, curTimestamp) {
      const curDate = new Date(curTimestamp);
      const year = curDate.getFullYear();
      const monthIndex = curDate.getMonth();
      const day = curDate.getDate();

      const newTime = time.split(":");
      const hours = newTime[0];
      const minutes = newTime[1];

      return new Date(year, monthIndex, day, hours, minutes).getTime();
    },
    submitForm() {
      this.success = null;
      this.error = null;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.loading = true;

        const payload = {
          data: {
            from: new Date(this.form.from.timestamp).toISOString(),
            to: new Date(this.form.to.timestamp).toISOString()
          }
        };

        this.sendMaintenanceInfo(payload)
          .then(() => {
            this.loading = false;
            this.success = "forms.maintenanceMail.success";
          })
          .catch(response => {
            this.loading = false;
            switch (response.data.status) {
              case -7503:
                this.error = "forms.maintenanceMail.errors.invalidTimespan";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
          });
      }
    }
  }
};
</script>
